<div class="state-filing">
	<div class="state-filing-body">
		<section class="upper-state-filling-popup">
			<form *ngFor="let item of this.getActiveFilings()" [formGroup]="item">
				<div class="state-header-wrapper">
					<p class="state-header">{{ this.driverName | uppercase }}</p>
					<button (click)="removeFiling(item)" class="material-icons">
						<span class="material-symbols-outlined"> delete_outlined </span>
					</button>
				</div>
				<div class="state-wrapper">
					<app-question-wrapper>
						<app-dropdown
							[labelInsideField]="'labels.filingState' | translate"
							[name]="Fields.FilingState.name"
							[options]="FieldOptions.FilingStatesOptions"
							[reAlphabeticalOrder]="false"
							searchable="true"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper>
						<app-dropdown
							[labelInsideField]="'labels.filingCode' | translate"
							[name]="Fields.FilingCode.name"
							[options]="FieldOptions.FilingCodeOptions"
							[reAlphabeticalOrder]="false"
						></app-dropdown>
					</app-question-wrapper>

					<app-question-wrapper>
						<unmasked-input
							[name]="Fields.FilingCase.name"
							[placeholder]="'labels.filingCase' | translate"
							maxlength="10"
							type="text"
						>
						</unmasked-input>
					</app-question-wrapper>

					<app-question-wrapper>
						<masked-input
							[inputMask]="'**/**/9999'"
							[name]="Fields.FilingOriginalDate.name"
							[placeholder]="'labels.filingOriginalDate' | translate"
							class="date-input"
						>
						</masked-input>
					</app-question-wrapper>
				</div>
			</form>
		</section>
		<app-button (click)="handleSave()" [disabled]="checkValidity()" [title]="'buttons.saveAndReturn' | translate">{{
			"buttons.saveAndReturn" | translate
		}}</app-button>
	</div>
</div>
