import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { hidePopupAction } from 'src/app/store/actions/popup.actions';
import { IAppState } from 'src/app/store/states/app.state';
import { Fields } from 'src/app/constants/fields';
import { BasePageComponent } from '../../pages/base-page.component';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import * as FieldOptions from '../../../../constants/field-options';
import { Validations } from 'src/app/utils/validation';
import { v4 as uuidv4 } from 'uuid';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { DriversService } from 'src/app/services/drivers.service';

@Component({
	selector: 'app-state-filing',
	templateUrl: './state-filing.component.html',
	styleUrls: ['./state-filing.component.scss'],
})
export class StateFilingPopupComponent extends BasePageComponent implements OnInit {
	data: any;
	form: FormGroup;
	Fields = Fields;
	formBuilder;
	FieldOptions = FieldOptions;
	driverName;
	SequenceNumCounter;

	constructor(
		protected store: Store<IAppState>,
		injector: Injector,
		private formattedDateService: FormattedDateService,
		private driversService: DriversService
	) {
		super(injector, store);
		this.formBuilder = injector.get(FormBuilder);
	}

	ngOnInit(): void {
		this.initForm();
	}

	initForm(): void {
		this.form = new FormGroup({});
		this.store
			.select(selectQuoteData)
			.pipe(first())
			.subscribe((res) => {
				this.form.addControl(
					'Filings',
					this.formBuilder.array(
						res[this.data.originPage][this.data.index].Filings.map((r) => {
							return this.formBuilder.group({
								[Fields.Id.name]: [r.Id, [Validators.required]],
								[Fields.SequenceNum.name]: [r.SequenceNum, [Validators.required]],
								[Fields.FilingState.name]: [r.FilingState, [Validators.required]],
								[Fields.FilingCode.name]: [r.FilingCode, [Validators.required]],
								[Fields.FilingCase.name]: [r.FilingCase, [Validators.required, Validations.alphabetAndDigitsAnd]],
								[Fields.FilingOriginalDate.name]: [
									this.formattedDateService.formatDateIfItIsNotMasked(r.FilingOriginalDate),
									[Validators.required, Validations.dateFormat, Validations.futureDate],
								],
							});
						})
					)
				);
				let SequenceNums = res[this.data.originPage][this.data.index].Filings.map((r) => r.SequenceNum);
				this.SequenceNumCounter = Math.max(...(SequenceNums.filter((drv) => drv !== -1).length ? SequenceNums : [0]));
				this.driverName =
					res[this.data.originPage][this.data.index].FirstName +
					' ' +
					res[this.data.originPage][this.data.index].LastName;
				if (!res[this.data.originPage][this.data.index].Filings.filter((drv) => drv.SequenceNum !== -1).length) {
					this.addStateFilingHandling();
				} else {
					this.SequenceNumCounter++;
				}
			});
	}

	addStateFilingHandling() {
		this.FilingsFormControl.push(
			new FormGroup({
				[Fields.Id.name]: new FormControl(uuidv4(), Validators.required),
				[Fields.SequenceNum.name]: new FormControl(this.SequenceNumCounter, Validators.required),
				[Fields.FilingState.name]: new FormControl(null, Validators.required),
				[Fields.FilingCode.name]: new FormControl(null, Validators.required),
				[Fields.FilingCase.name]: new FormControl(null, [Validators.required, Validations.alphabetAndDigitsAnd]),
				[Fields.FilingOriginalDate.name]: new FormControl(null, [
					Validators.required,
					Validations.dateFormat,
					Validations.futureDate,
				]),
			})
		);
	}

	get FilingsFormControl(): FormArray {
		return this.form.get('Filings') as FormArray;
	}

	handleSave() {
		this.driversService.editFiling({ index: this.data.index, Filings: this.form.value.Filings });
		this.store.dispatch(hidePopupAction());
	}

	removeFiling(driver) {
		this.FilingsFormControl.controls[this.FilingsFormControl.value.findIndex((i) => i.Id == driver.get('Id').value)]
			.get('SequenceNum')
			.setValue(-1);
	}

	getActiveFilings() {
		return this.form.get('Filings')['controls'].filter((i) => i.value.SequenceNum !== -1);
	}

	checkValidity(){
		return this.getActiveFilings().map(i=>i.valid).includes(false);
	}

	handleCancel() {
		this.store.dispatch(hidePopupAction());
	}
}
